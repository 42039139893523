import { SequentialTaskRunner } from "../core/SequentialTaskRunner";
import { Syncer } from "../core/Syncer";

export class SyncService {
  private static intervalId: NodeJS.Timer | null = null;
  private static sequentialTaskRunner: SequentialTaskRunner = new SequentialTaskRunner();
  private static syncers: Syncer[] = [];
  private static checkSignIn: () => boolean = () => false;

  public static enqueue(): void {
    this.sequentialTaskRunner.enqueue(async () => {
      const isSignedIn = this.checkSignIn();
      const changesChecks = new Map<Syncer, boolean>();
      for (const syncer of this.syncers) {
        changesChecks.set(
          syncer,
          isSignedIn ? await syncer.changesTracker.check() : false,
        );
      }
      for (const syncer of this.syncers) {
        await syncer.sync(isSignedIn, !!changesChecks.get(syncer));
      }
    });
  }

  public static run(
    interval: number,
    checkSignIn: () => boolean,
    syncers: Syncer[],
  ): void {
    if (!this.intervalId) {
      this.syncers = syncers;
      this.checkSignIn = checkSignIn;
      this.intervalId = setInterval(() => this.enqueue(), interval);
    }
  }

  public static stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
