import { Avatar, Box, Stack, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import EditIcon from "../../assets/Icons/edit.svg";
import {
  getAndSaveUserProfile,
  updateLocalProfilePic,
  updateProfile,
} from "../../store/auth/authSlice";
import { selectAuthState } from "../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LoginInput } from "../LoginInput/LoginInput";

import "./SettingProfile.scss";
export type SettingProfileProps = {
  onClose: () => void;
};

export const SettingProfile = (props: SettingProfileProps) => {
  const { user } = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();

  const [name, setName] = useState(user?.name);
  const [img, setImg] = useState(user?.profileUrl);

  useEffect(() => {
    setImg(user?.profileUrl);
    setName(user?.name);
  }, [user]);

  const handleSave = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }
    const url = await dispatch(
      updateLocalProfilePic(event.target.files[event.target.files.length - 1]),
    );
    setImg(url);

    // Reset the input value
    event.target.value = "";
  };
  const handleComplete = async () => {
    if (img !== user?.profileUrl || name != user?.name) {
      await dispatch(updateProfile({ name: name, profileUrl: img }));
    }
    props.onClose();
  };
  const handleClose = async () => {
    await dispatch(getAndSaveUserProfile());
    props.onClose();
  };
  return (
    <Stack className="profile">
      <Box className="profile-header">
        <Typography className="profile-title green" onClick={handleClose}>
          Отменить
        </Typography>
        <Typography className="profile-title black">Мои Данные</Typography>
        <Typography
          className={
            "profile-title finished  " +
            (name != user?.name || img !== user?.profileUrl ? "green" : "gray")
          }
          onClick={handleComplete}
        >
          Готово
        </Typography>
      </Box>
      <Box className="profile-image__container">
        <Avatar className="profile-image" src={img || ""} />
        <input
          type="file"
          accept="image/*"
          className="profile-image-edit-input"
          id="fileInput"
          onChange={handleSave}
        />
        <label htmlFor="fileInput">
          <img src={EditIcon} alt="edit" className="profile-image-edit" />
        </label>
      </Box>
      <LoginInput
        name="Ваше имя"
        type="text"
        value={name || ""}
        onChange={(e) => {
          setName(e);
        }}
      />
    </Stack>
  );
};
