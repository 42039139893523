import { AppBar, Divider, SelectChangeEvent, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { useSearchParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import config from "../../../appConfig/config";
import { ListIcon } from "../../../assets/Icons/ListIcon";
import { AppMenu } from "../../../components/AppMenu/AppMenu";
import { AppTopBar } from "../../../components/AppTopBar/AppTopBar";
import { BaseButton } from "../../../components/BaseButton/BaseButton";
import { AcceptListDialog } from "../../../components/Dialogs/AcceptListDialog/AcceptListDialog";
import { CreateListDialog } from "../../../components/Dialogs/CreateListDialog/CreateListDialog";
import { DeleteDialog } from "../../../components/Dialogs/DeleteDialog/DeleteDialog";
import { EditListItemDialog } from "../../../components/Dialogs/EditListItemDialog/EditListItemDialog";
import { ShareListDialog } from "../../../components/Dialogs/ShareListDialog/ShareListDialog";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { ListItemCreationToolbar } from "../../../components/ListItemCreationToolbar/ListItemCreationToolbar";
import { ListItems } from "../../../components/ListItems/ListItems";
import { ListsToolbar } from "../../../components/ListsToolbar/ListsToolbar";
import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  enqueueSync,
  runSync,
  stopSync,
} from "../../../store/application/applicationSlice";
import { selectApplicationState } from "../../../store/application/selectors";
import { logoutCleanup } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import {
  addCategory,
  deleteOneCategory,
  fetchCategories,
  updateCategory,
} from "../../../store/categories/categoriesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createListItem,
  deleteListItems,
  fetchListItems,
  updateListItem,
  getListByShareKey,
  acceptSharedList,
  createList,
  removeList,
  selectList,
  getShareListKey,
  changeListItemCategory,
  fetchLists,
  setShareListKeyForAccept,
  saveSelectedList,
  deleteCategoryFromListItems,
} from "../../../store/lists/listsSlice";
import {
  selectCompletedListItems,
  selectGroupedByCategoryListItems,
  selectListsState,
} from "../../../store/lists/selectors";
import { fetchPrompts } from "../../../store/prompts/promptsSlice";
import { URL_PARAMS } from "../../../utils/constants";
import normalizeText from "../../../utils/normalizeText";
import "./ListPage.scss";

export const ListsPage = () => {
  const dispatch = useAppDispatch();
  const isPageVisible = usePageVisibility();

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    lists,
    listItems,
    selectedList,
    listToAccept,
    shareListKeyForSharing,
    shareListKeyForAccept,
    areListItemsLoading,
    areListsLoading,
  } = useAppSelector(selectListsState);
  const groupedByCategoryListItems = useAppSelector(selectGroupedByCategoryListItems);
  const completedListItems = useAppSelector(selectCompletedListItems);
  const { user, signedIn } = useAppSelector(selectAuthState);
  const { isOnline } = useAppSelector(selectApplicationState);
  const { prompts } = useAppSelector((state) => state.prompts);
  const { categories } = useAppSelector((state) => state.categories);
  const [isAddListDialogOpen, setIsAddListDialogOpen] = useState(false);
  const [isDeleteListDialogOpen, setIsDeleteListDialogOpen] = useState(false);
  const [isShareListDialogOpened, setIsShareListDialogOpened] = useState(false);
  const [isAppMenuOpened, setIsAppMenuOpened] = useState(false);
  const [isTextListItemCreationOpened, setIsTextListItemCreationOpened] = useState(false);
  const [isEditListItemDialogOpened, setIsEditListItemDialogOpened] = useState(false);
  const [listItemLocalIdToEdit, setListItemLocalIdToEdit] = useState(0);
  const [isAcceptListDialogOpen, setIsAcceptListDialogOpen] = useState(false);

  const handleCreateList = (name: string) => {
    setIsAddListDialogOpen(false);
    dispatch(createList(name));
  };

  const handleDeleteList = () => {
    setIsDeleteListDialogOpen(false);
    if (selectedList) {
      dispatch(removeList(selectedList.localId));
    }
  };

  const handleSelectList = (event: SelectChangeEvent) => {
    const listId = +event.target.value;
    dispatch(selectList({ localId: listId }));
  };

  const handleFetchShareListKey = (listId: number | null) => {
    if (listId === null) {
      dispatch(enqueueSync());
    } else {
      dispatch(getShareListKey(listId));
    }
  };

  const handleAddTextListItemClick = () => {
    setIsTextListItemCreationOpened(!isTextListItemCreationOpened);
  };

  const handleAddVoiceListItemClick = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ language: "ru-RU" }).catch(console.log);
    } else {
      // TODO: display banner your browser doesn't support speech recognition.
    }
  };

  const handleCancelListItemTextCreation = () => {
    setIsTextListItemCreationOpened(false);
  };

  const handleCancelListItemVoiceCreation = () => {
    SpeechRecognition.stopListening();
  };

  const handleListItemCreation = (input: string) => {
    if (selectedList) {
      for (const listItemName of input.split(",")) {
        if (!listItemName.trim().length) {
          continue;
        }
        const prompt = prompts.find(
          (p) => normalizeText(p.name) === normalizeText(listItemName),
        );
        const category = categories.find((c) => c.localId === prompt?.localCategoryId);
        dispatch(
          createListItem({
            name: listItemName,
            localListId: selectedList.localId,
            localCategory: category ?? null,
          }),
        );
      }
    }
  };

  const handleCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: true }));
    }
  };

  const handleUnCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: false }));
    }
  };

  const handleDeleteAllListItems = () => {
    if (selectedList) {
      dispatch(
        deleteListItems({
          listItemsToDelete: completedListItems,
        }),
      );
    }
  };

  const handleLogout = () => {
    dispatch(logoutCleanup()).then(() => {
      window.location.reload();
    });
  };

  const handleOpenEditListItemDialog = (listItemLocalId: number) => {
    setListItemLocalIdToEdit(listItemLocalId);
    setIsEditListItemDialogOpened(true);
  };

  const handleCloseEditListItemDialog = () => {
    setIsEditListItemDialogOpened(false);
  };

  const handleListItemCategoryChange = (
    category: ListItemCategoryInternalModel | null,
  ) => {
    setIsEditListItemDialogOpened(false);
    dispatch(
      changeListItemCategory({ listItemLocalId: listItemLocalIdToEdit, category }),
    );
  };

  const handleCreateCategory = (name: string, color: string) => {
    dispatch(addCategory({ name, color }));
  };

  const handleEditCategory = async (editedCategory: ListItemCategoryInternalModel) => {
    await dispatch(updateCategory(editedCategory));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };
  const handleDeleteCategory = async (category: ListItemCategoryInternalModel) => {
    await dispatch(deleteOneCategory(category));
    await dispatch(deleteCategoryFromListItems(category.localId));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };

  const handleAcceptList = () => {
    if (shareListKeyForAccept && listToAccept) {
      dispatch(acceptSharedList({ listToAccept, shareListKeyForAccept, signedIn }));
    }
    setIsAcceptListDialogOpen(false);
  };

  const handleRejectList = () => {
    setIsAcceptListDialogOpen(false);
  };

  useEffect(() => {
    const shareListKeyForAccept = searchParams.get(URL_PARAMS.SHARE_LIST_KEY);
    if (shareListKeyForAccept) {
      dispatch(setShareListKeyForAccept(shareListKeyForAccept));
      dispatch(getListByShareKey(shareListKeyForAccept))
        .then(() => {
          setIsAcceptListDialogOpen(true);
        })
        .finally(() => {
          searchParams.delete(URL_PARAMS.SHARE_LIST_KEY);
          setSearchParams(searchParams);
        });
    }
    return () => {
      dispatch(stopSync());
    };
  }, []);

  useEffect(() => {
    if (selectedList?.localId) {
      dispatch(fetchListItems(selectedList.localId));
      dispatch(saveSelectedList(selectedList.localId));
    }
  }, [dispatch, selectedList]);

  useEffect(() => {
    if (selectedList && !listening && transcript !== null && transcript.length > 1) {
      handleListItemCreation(normalizeText(transcript).replace(".", " "));
      resetTranscript();
    }
  }, [listening]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLists());
    dispatch(fetchPrompts());
    dispatch(enqueueSync());
  }, [user]);

  useEffect(() => {
    if (isPageVisible) {
      dispatch(runSync(config.SyncListsInterval));
      dispatch(enqueueSync());
    } else {
      dispatch(stopSync());
    }
  }, [isPageVisible]);

  return (
    <Stack height={"100dvh"} overflow={"hidden"}>
      <AppBar position="static">
        <AppTopBar isOnline={isOnline} onMenuClick={() => setIsAppMenuOpened(true)}>
          <ListsToolbar
            lists={lists}
            selectedList={selectedList}
            user={user}
            onAddListClick={() => setIsAddListDialogOpen(true)}
            onDeleteListClick={() => setIsDeleteListDialogOpen(true)}
            onShareListClick={() => setIsShareListDialogOpened(true)}
            onSelectList={handleSelectList}
          />
        </AppTopBar>
        <AppMenu
          open={isAppMenuOpened}
          onOpen={() => setIsAppMenuOpened(true)}
          onClose={() => setIsAppMenuOpened(false)}
          user={user}
          onSignOutClick={handleLogout}
          onAddListClick={() => setIsAddListDialogOpen(true)}
        />
      </AppBar>
      <Stack
        component="main"
        flexGrow={
          groupedByCategoryListItems.length ||
          completedListItems.length ||
          isTextListItemCreationOpened
            ? "0"
            : "1"
        }
      >
        {selectedList && !areListItemsLoading && (
          <Stack flexGrow="1" justifyContent="space-between" alignItems={"center"}>
            <Typography className="list-items-title" component="h2">
              Список
            </Typography>
            <Divider className="list-items-divider" />
            <ListItems
              groupedByCategoryListItems={groupedByCategoryListItems}
              completedListItems={completedListItems}
              prompts={prompts}
              categories={categories}
              isListItemCreationOpened={isTextListItemCreationOpened}
              onListItemComplete={handleCompleteListItem}
              onListItemUnComplete={handleUnCompleteListItem}
              onDeleteAllListItems={handleDeleteAllListItems}
              onEditListItemClick={handleOpenEditListItemDialog}
              onSaveNewListItem={handleListItemCreation}
              onCancelListItemCreation={handleCancelListItemTextCreation}
            />
            <ListItemCreationToolbar
              listening={listening}
              onAddTextListItemClick={handleAddTextListItemClick}
              onAddVoiceListItemClick={handleAddVoiceListItemClick}
              onCancelVoiceListeningClick={handleCancelListItemVoiceCreation}
            />
          </Stack>
        )}
        {!selectedList && !areListsLoading && (
          <EmptyContent
            image={<ListIcon />}
            title="Добавьте список"
            subtitle="Создайте новый список чтобы иметь возможность добавлять продукты"
            button={
              <BaseButton onClick={() => setIsAddListDialogOpen(true)}>
                Создать список
              </BaseButton>
            }
          />
        )}
      </Stack>
      <CreateListDialog
        open={isAddListDialogOpen}
        onClose={() => setIsAddListDialogOpen(false)}
        onAdd={handleCreateList}
      />
      <AcceptListDialog
        open={isAcceptListDialogOpen}
        onAccept={handleAcceptList}
        onReject={handleRejectList}
        list={listToAccept}
      />
      <EditListItemDialog
        open={isEditListItemDialogOpened}
        onClose={handleCloseEditListItemDialog}
        onCategoryChange={handleListItemCategoryChange}
        onCategoryCreate={handleCreateCategory}
        onCategoryEdit={handleEditCategory}
        onCategoryDelete={handleDeleteCategory}
        categories={categories}
        listItem={listItems.find((li) => li.localId === listItemLocalIdToEdit)}
      />
      {selectedList && (
        <>
          <DeleteDialog
            open={isDeleteListDialogOpen}
            name={selectedList.name}
            onClose={() => setIsDeleteListDialogOpen(false)}
            onOk={handleDeleteList}
            type="cписок"
          />
          <ShareListDialog
            open={isShareListDialogOpened}
            onClose={() => setIsShareListDialogOpened(false)}
            signedIn={signedIn}
            onFetchShareKey={handleFetchShareListKey}
            shareListKey={shareListKeyForSharing}
            selectedList={selectedList}
          />
        </>
      )}
    </Stack>
  );
};
