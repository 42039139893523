import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./SignUpPage.scss";
import { Cancel } from "../../../assets/Icons/Cancel";
import { EyeClosed } from "../../../assets/Icons/EyeClosed";
import { EyeOpened } from "../../../assets/Icons/EyeOpened";
import { Alert } from "../../../components/Alert/Alert";
import { AcceptConditionsDialog } from "../../../components/Dialogs/AcceptConditionDialog/AcceptConditionDialog";
import { LoginInput } from "../../../components/LoginInput/LoginInput";
import { LoginQuestion } from "../../../components/LoginQuestion/LoginQuestion";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { signUp } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setEmail, setPassword } from "../../../store/login/loginSlice";
import { selectLoginState } from "../../../store/login/selector";
import { emailRegex, Paths } from "../../../utils/constants";

export const SignUpPage = () => {
  const dispatch = useAppDispatch();
  const { email, password } = useAppSelector(selectLoginState);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { signedIn } = useAppSelector(selectAuthState);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isSigneUpError, setIsSigneUpError] = useState(false);
  const [isConditionsDialogOpened, setIsConditionsDialogOpened] = useState(false);

  useEffect(() => {
    if (signedIn) {
      navigate("/");
    }
  }, [signedIn, navigate]);

  useEffect(() => {
    setIsSigneUpError(false);
  }, [email, password]);

  const handleSignUp = async () => {
    setIsConditionsDialogOpened(false);
    if (emailRegex.test(email) && password) {
      setIsSigningUp(true);
      const signUpAction = await dispatch(signUp({ email: email, password }));
      setIsSigningUp(false);
      if (!signUpAction.payload) {
        setIsSigneUpError(true);
      } else {
        window.location.reload();
      }
    }
  };

  if (signedIn) {
    return null;
  }

  return (
    <Stack className="signup-container">
      <LoginTitle text="Создание аккаунта" backPath={Paths.LOGIN_HOME} />
      <Box className="signup-inputs">
        <LoginInput
          value={email}
          onChange={(val) => dispatch(setEmail(val))}
          icon={email && <Cancel />}
          name="Ваш адрес электронной почты"
          onIconClick={() => dispatch(setEmail(""))}
          type="email"
          errorText="Email должен быть формата test@test.ru"
          isError={showError && !emailRegex.test(email)}
        />
        <LoginInput
          value={password}
          onChange={(val) => dispatch(setPassword(val))}
          icon={isPasswordVisible ? <EyeOpened /> : <EyeClosed />}
          name="Ваш пароль"
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          type={isPasswordVisible ? "text" : "password"}
          errorText="Обязательно для заполнения"
          isError={showError && password.length === 0}
        />
        {isSigneUpError && (
          <Alert type="danger">
            Пользователь с таким email уже существует.{" "}
            <NavLink className="signup-alert-link" to={Paths.LOGIN}>
              Войти?
            </NavLink>
          </Alert>
        )}
        {/*<Box className="signup-warning" onClick={() => setIsCheckOpen(true)}>*/}
        {/*  <Typography>Забыли пароль?</Typography>*/}
        {/*</Box>*/}
      </Box>
      <Box className="signup-bottom">
        <Button
          className="signup-continue"
          variant="contained"
          onClick={() => {
            setShowError(true);
            if (emailRegex.test(email) && password) {
              setIsConditionsDialogOpened(true);
            }
          }}
        >
          Продолжить
        </Button>
        <LoginQuestion
          question="Уже есть аккаунт?"
          linkText="Вход"
          linkAddress={Paths.LOGIN}
        />
      </Box>
      {isSigningUp && <PageLoading />}
      <AcceptConditionsDialog
        onAccept={handleSignUp}
        open={isConditionsDialogOpened}
        onClose={() => setIsConditionsDialogOpened(false)}
      />
    </Stack>
  );
};
