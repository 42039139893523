import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import "./AcceptConditionDialog.scss";

export interface SignInDialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const AcceptConditionsDialog = (props: SignInDialogProps) => {
  return (
    <Dialog open={props.open} onClose={() => props.onClose} className="google-dialog">
      <DialogTitle className="google-dialog-title">Принять наши условия</DialogTitle>
      <DialogContent className="google-dialog-content">
        <Typography>
          Продолжая, вы соглашаетесь с{" "}
          <span className="google-dialog-special">Условиями использования Listy </span>и
          принимаете{" "}
          <span className="google-dialog-special">Политику конфиденциальности</span>
        </Typography>
      </DialogContent>
      <DialogActions className="google-dialog-actions">
        <Button onClick={() => props.onClose()} className="google-dialog-cancel">
          Отменить
        </Button>
        <Button onClick={() => props.onAccept()} className="google-dialog-accept">
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
