import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { Paths } from "../../../utils/constants";
import { ShareList } from "../../ShareList/ShareList";
import "./ShareListDialog.scss";

export interface ShareListDialogProps {
  open: boolean;
  onClose: () => void;
  signedIn: boolean;
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
}

export const ShareListDialog = (props: ShareListDialogProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="dialog">
      <DialogTitle className="dialog-title">
        {props.signedIn
          ? "Поделиться списком"
          : "Для того чтобы поделиться списком, необходимо войти в приложение"}
      </DialogTitle>
      <IconButton aria-label="close" onClick={handleClose} className="dialog-close">
        <Close />
      </IconButton>
      <DialogContent>
        {props.signedIn ? (
          <ShareList
            onFetchShareKey={props.onFetchShareKey}
            shareListKey={props.shareListKey}
            selectedList={props.selectedList}
            close={props.onClose}
          />
        ) : (
          <Button
            className="share-list-dialog-button"
            onClick={() => navigate(Paths.LOGIN)}
            variant="contained"
          >
            Продолжить
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};
