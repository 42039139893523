import "./CategoryEditing.scss";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { CATEGORIES_COLORS } from "../../utils/constants";
import { BaseInput } from "../BaseInput/BaseInput";
import { ColorPicker } from "../ColorPicker/ColorPicker ";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";
import { DeleteDialog } from "../Dialogs/DeleteDialog/DeleteDialog";

export interface CategoryEditingProps {
  categories: ListItemCategoryInternalModel[];
  categoryToEdit: ListItemCategoryInternalModel;
  onCategoryEdit: (editedCategory: ListItemCategoryInternalModel) => void;
  onCategoryDelete: (category: ListItemCategoryInternalModel) => void;
  onBack: () => void;
}

export const CategoryEditing = (props: CategoryEditingProps) => {
  const [newCategoryName, setNewCategoryName] = useState(props.categoryToEdit.name);
  const [newCategoryColor, setNewCategoryColor] = useState(props.categoryToEdit.color);
  const [newCategoryError, setNewCategoryError] = useState("");
  const [isOpenDeleteDiaog, setIsOpenDeleteDialog] = useState(false);
  const categoriesNamesSet = useMemo(
    () => new Set(props.categories.map((c) => c.name)),
    [props.categories],
  );
  const handleDeleteCategoty = () => {
    setIsOpenDeleteDialog(false);
    props.onCategoryDelete(props.categoryToEdit);
  };

  const handleNewCategoryInputChange = (inputValue: string) => {
    if (inputValue !== props.categoryToEdit.name && categoriesNamesSet.has(inputValue)) {
      setNewCategoryError("Такая категория уже есть");
    } else {
      setNewCategoryError("");
    }
    setNewCategoryName(inputValue);
  };

  const handleEditCategory = () => {
    if (newCategoryName && newCategoryColor && !newCategoryError) {
      props.onCategoryEdit({
        ...props.categoryToEdit,
        name: newCategoryName,
        color: newCategoryColor,
        colorDark: newCategoryColor,
        updated: new Date().toISOString(),
      });
      props.onBack();
    }
  };

  return (
    <Box className="category-editing">
      <CurtainPopupHeading
        title="Редактирование категории"
        onBack={props.onBack}
        onConfirm={handleEditCategory}
        isConfirmDisabled={!newCategoryName || !newCategoryColor || !!newCategoryError}
      />
      <BaseInput
        className="category-editing-input"
        value={newCategoryName}
        placeholder={"Введите название категории"}
        onChange={(e) => handleNewCategoryInputChange(e.target.value)}
        endAdornment={
          newCategoryName && (
            <InputAdornment
              position="end"
              onClick={() => handleNewCategoryInputChange("")}
            >
              <HighlightOffOutlinedIcon />
            </InputAdornment>
          )
        }
        onEnter={handleEditCategory}
        errorMessage={newCategoryError}
      />
      <Button
        className="category-editing-delete-button"
        variant="text"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={() => setIsOpenDeleteDialog(true)}
      >
        Удалить категорию
      </Button>
      <Accordion disableGutters defaultExpanded className="category-editing-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="category-editing-accordion-title">
            Цвет категории
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ColorPicker
            colors={CATEGORIES_COLORS}
            selectedColor={newCategoryColor}
            onColorSelect={setNewCategoryColor}
          />
        </AccordionDetails>
      </Accordion>
      <DeleteDialog
        name={props.categoryToEdit.name}
        type="категорию"
        onClose={() => setIsOpenDeleteDialog(false)}
        onOk={() => handleDeleteCategoty()}
        open={isOpenDeleteDiaog}
      />
    </Box>
  );
};
